/** @format */

import { Localized } from "@fluent/react";
import { IconChevronLeft, IconImage } from "components/icons";
import { LvtBtn, LvtCol, LvtLink, LvtRow, LvtText } from "components/lvtComponents/components";
import NavigationWrapper from "components/wrappers/navigationWrapper";
import { performersSinglePath } from "constants/paths";
import { useGenerateMultilingualData } from "hooks/generic";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

const PerformerList = ({ singleListID }) => {
	const { id } = useParams();
	const history = useHistory();
	const idToUse = singleListID || id;
	const { performers: performerLists = [] } = useSelector(state => state);
	const performerList = performerLists.find(pl => pl.id === parseInt(idToUse));
	const { title = {}, performers = [] } = performerList;
	const { generate } = useGenerateMultilingualData();
	const tileToShow = generate(title);
	return (
		<NavigationWrapper className="performers">
			<LvtRow justify="between">
				<LvtBtn set="link" className="back" onClick={() => history.goBack()}>
					<IconChevronLeft className="back-icon" /> <Localized id="back" />
				</LvtBtn>
				<LvtText set="h1" className="lvt-text-h3 performerlist-heading">
					{tileToShow}
				</LvtText>
			</LvtRow>
			<div className="performer-list">
				{performers.map(p => (
					<Performer {...p} key={`performer-${p.id}`} />
				))}
			</div>
		</NavigationWrapper>
	);
};

const Performer = props => {
	const { slug, id } = useParams();
	const { name = {}, image = null, title = {}, id: performer_id } = props;
	const { generate } = useGenerateMultilingualData();
	const titleToShow = generate(title);
	const nameToShow = generate(name);

	return (
		<LvtLink to={performersSinglePath(slug, performer_id)} className="performer">
			<PerformerImage image={image} alt="" />
			<LvtCol className="performer-info">
				<LvtText set="h2" className="lvt-text-h4 performer-name">
					{nameToShow}
				</LvtText>
				<LvtText set="label" className="performer-title">
					{titleToShow}
				</LvtText>
			</LvtCol>
		</LvtLink>
	);
};

const PerformerImage = ({ image, alt }) => {
	return image ? (
		<img src={image} alt={alt} className="performer-image" />
	) : (
		<div className="performer-image-placeholder">
			<IconImage size="100" className="icon" />
		</div>
	);
};

export default PerformerList;
